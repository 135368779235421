import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-menu-taberna',
  templateUrl: './menu-taberna.component.html',
  styleUrls: ['./menu-taberna.component.css']
})
export class MenuTabernaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Comprobar si se tiene acceso a Casa Manola en este entorno
   */
  public comprobarAccesoCasaManola(): boolean {
    return environment.isCasaManolaVisible;
  }

  /**
     * Comprobar si se tiene acceso a Casa Lola en este entorno
     */
  public comprobarAccesoCasaLola(): boolean {
    return environment.isCasaLolaVisible;
  }

}
