import { Injectable } from '@angular/core';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MapUtilService {

  constructor() { }

  /**
   * Saber si estamos en un tablet o movil
   * @returns true si es tablet o movil, false si es desktop
   */
  public isMobile() : Boolean {
    let isMobile: Boolean = false;
    if( navigator.userAgent.match("/Android/i")
      || navigator.userAgent.match("/webOS/i")
      || navigator.userAgent.match("/iPhone/i")
      || navigator.userAgent.match("/iPad/i")
      || navigator.userAgent.match("/iPod/i")
      || navigator.userAgent.match("/BlackBerry/i")
      || navigator.userAgent.match("/Windows Phone/i")) {
        isMobile = true;
      }
      return isMobile;
  }

  /**
   * Crea Map
   * @param latitud 
   * @param longitud 
   * @param id 
   * @param tituloPopUp 
   * @param urlMapIOS 
   * @param urlMapAndroid 
   * @param urlMapDestop 
   * @returns 
   */
  public createMapMarker (latitud: number, longitud: number, id: string, tituloPopUp: string, urlMapIOS: string, urlMapAndroid: string, urlMapDestop: string) : L.Map {

    let map: L.Map;

    // Icono Map Mella
    var mellaIcon = L.icon({
      iconUrl: 'assets/puntero_mapa_CM.png',
  
      iconSize:     [54, 64], // size of the icon
      shadowSize:   [54, 64], // size of the shadow
      iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    map = L.map(id, {
      center: [latitud, longitud],
      zoom: 16
    });

    let markerMap = L.marker([latitud, longitud], {icon: mellaIcon}).bindPopup("<b>"+ tituloPopUp +"</b>");

    markerMap.openPopup().on('click', function(e) {
      console.info("Click en Mapa, lanzamos url Google Maps");
      if (navigator.userAgent.match("/Android/i")
      || navigator.userAgent.match("/webOS/i")
      || navigator.userAgent.match("/BlackBerry/i")
      || navigator.userAgent.match("/Windows Phone/i")) {
        window.open(urlMapAndroid, "_blank");
      } else if (
        navigator.userAgent.match("/iPhone/i")
      || navigator.userAgent.match("/iPad/i")
      || navigator.userAgent.match("/iPod/i")) {
        window.open(urlMapIOS, "_blank");
      } else {
        window.open(urlMapDestop, "_blank");
      }
    });

    markerMap.addTo(map);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(map);

    return map;
  }

}
