import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

import { MapUtilService } from 'src/app/core/map-util/map-util.service';

@Component({
  selector: 'app-map-casa-lola',
  templateUrl: './map-casa-lola.component.html',
  styleUrls: ['./map-casa-lola.component.css']
})
export class MapCasaLolaComponent implements OnInit {

  constructor(private mapUtilService: MapUtilService) { }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {

    let map: L.Map;
    
    map = this.mapUtilService.createMapMarker(
      42.781577, -8.562197, 
      'map', 'Casa Lola', 
      'https://maps.apple.com/?address=Calle%20Raris,%2080,%2015883%20Teo,%20A%20Coru%C3%B1a,%20Espa%C3%B1a&auid=4991003400741219169&ll=42.781389,-8.562325&lsp=9902&q=Casa%20Lola&_ext=CjIKBQgEEM4BCgQIBRADCgQIBhALCgQIChAACgQIUhADCgQIVRAQCgQIWRAGCgUIpAEQARImKcSDd/RrY0VAMRO2glIXIyHAOUJZnVCSZEVAQWkRuBPTHCHAUAQ%3D&t=m', 
      'https://goo.gl/maps/78EgWsBEUVAGrmDD7', 'https://goo.gl/maps/78EgWsBEUVAGrmDD7');
  
  }

}
