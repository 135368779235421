import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Services
import { CasaManolaComponent } from './casa-manola/casa-manola.component';
import { TabernaMellaComponent } from './taberna-mella/taberna-mella.component';
import { CasaLolaComponent } from './casa-lola/casa-lola.component';
import { Error404Component } from './generic/error404/error404.component';

const routes: Routes = [
  { path: '', redirectTo: '/casa-manola', pathMatch: 'full' },
  { path: 'casa-manola', component: CasaManolaComponent },
  { path: 'taberna-mella', component: TabernaMellaComponent },
  { path: 'casa-lola', component: CasaLolaComponent },
  { path: '404', component: Error404Component },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
