import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

// Models
import { IpApi } from '../Models/ipApi';
import { Resultado } from '../Models/response';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private http: HttpClient) { }

  /**
   * Consultar IP cliente
   */
  public consultarIpInfo(): Observable<IpApi> {
    return this.http.get<IpApi>("https://ipinfo.io/json");
  }

  /**
   * Insertar iteracion en WEB
   */
  public insertarIteracionWeb(
    ip: string, web: string, pais: string, region: string, ciudad: string,
    codigoPostal: string, coordenadas: string, os: string, dispositivo: string, navegador: string): Observable<Resultado> {
    const headers: HttpHeaders = new HttpHeaders({
      "authorization": "f67516a483e583a9eac012f74a0787b7",
      "content-type": "application/json"
    });
    let params = new HttpParams().set('ip', ip).set('web', web).set('pais', pais)
      .set('region', region).set('ciudad', ciudad).set('codigoPostal', codigoPostal)
      .set('cordenadas', coordenadas).set('os', os).set('dispositivo', dispositivo)
      .set('navegador', navegador);
    return this.http.get<Resultado>(
      "https://www.reservas.casamanola.gal/wp-json/visitas/v2/insertarEvento",
      { params: params, headers: headers });
  }

}
