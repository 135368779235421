import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/core/http-client/http-client.service';

// Models
import { Idioma } from './../core/Models/idioma';
import { IpApi } from './../core/Models/ipApi';

@Component({
  selector: 'app-util-idioma-menu',
  templateUrl: './util-idioma-menu.component.html',
  styleUrls: ['./util-idioma-menu.component.css']
})
export class UtilIdiomaMenuComponent implements OnInit {

  public activeLang = 'gl';

  public listaIdiomas: Idioma[] = [
    { idiomaMostrar: 'GL', idiomaISO: 'gl' },
    { idiomaMostrar: 'ES', idiomaISO: 'es' },
    { idiomaMostrar: 'PT', idiomaISO: 'pt' },
    { idiomaMostrar: 'EN', idiomaISO: 'en' }
  ];

  constructor(
    public translate: TranslateService,
    public httpClientService: HttpClientService
  ) {
    console.info("Calculando Default lang");
    if (this.obtenerIdiomaDeSesion() === '') {
      this.translate.setDefaultLang(this.activeLang);
      this.guardarIdiomaEnSesion(this.activeLang);
      this.calcularIpCliente();
    } else {
      this.translate.setDefaultLang(this.obtenerIdiomaDeSesion());
    }

  }

  ngOnInit(): void {
  }

  // Cambiar Idioma
  public cambiarIdioma(lang: string): void {
    console.log('Cambiar idioma: ', lang);
    this.activeLang = lang;
    this.translate.use(lang);
    this.guardarIdiomaEnSesion(this.activeLang);
  }

  /**
   * Guardar Idioma en Sesion
   */
  private guardarIdiomaEnSesion(lang: string): void {
    sessionStorage.setItem('lang', lang);
  }

  /**
   * Obtener Idioma de Sesion
   * @returns lang
   */
  private obtenerIdiomaDeSesion(): string {
    var lang: string = '';
    if (sessionStorage.getItem('lang') != null) {
      lang = sessionStorage.getItem('lang') + '';
    }
    return lang;
  }

  /**
   * Calcular IpCliente
   */
  public calcularIpCliente(): void {
    console.log("Calculando IP Cliente");
    this.httpClientService.consultarIpInfo().subscribe(
      (resultado: IpApi) => {
        console.log("Response IP Cliente: " + resultado);
        if (resultado != null) {
          this.cambiarIdioma(this.comprobarRegion(resultado));
        }
      },
      () => (console.error("Error al llamar a IpApi")),
      () => { });
  }

  /**
   * Comrpobar region
   * @param ipApi 
   * @returns 
   */
  public comprobarRegion(ipApi: IpApi) {
    let lang: string = 'es';
    console.log("Se encuentra en la region " + ipApi.region);
    if (ipApi.region === 'Galicia') {
      lang = 'gl';
    } else if (ipApi.country === 'PT') {
      lang = 'pt';
    } else {
      lang = 'es';
    }
    return lang;
  }

}
