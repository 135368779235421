<app-menu-taberna></app-menu-taberna>

<section id="inicio" class="d-flex flex-column justify-content-center">
    <div id="container">
        <h1>Taberna Mella 1926</h1>
        <div class="social-links">
            <a href="https://es-es.facebook.com/Taberna-Mella-dende-1926-192998264366449/" class="facebook"><i
                    class="bx bxl-facebook bx-md"></i></a>
            <!--<a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>-->
        </div>
    </div>
    <div id="container-foto" class="d-flex flex-column justify-content-center"></div>
</section>

<app-map-taberna-mella></app-map-taberna-mella>