<app-menu></app-menu>

<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <div></div>
            <h1>404</h1>
        </div>
        <h2>{{ '404.error.principal' | translate }}</h2>
        <p>{{ '404.error.descripcion' | translate }}</p>
        <a href="/">{{ '404.error.pagina' | translate }}</a>
    </div>
</div>