import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/http-client/http-client.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Cookie } from 'ng2-cookies';

// Models
import { Resultado } from '../Models/response';
import { IpApi } from '../Models/ipApi';

@Injectable({
  providedIn: 'root'
})
export class RegistrarAccesoService {

  constructor(
    public httpClientService: HttpClientService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) { }

  /**
   * Insertar visita en WEB
   */
  public insertarIteraccionEnWeb() {

    //if (Cookie.get("visita_web_activa") == null || Cookie.get("visita_web_activa") == "") {
    this.buscarDatosUsuario();
    // Crear cookie visita
    this.crearCookieVisita();
    //}
  }

  /**
   * Crear cookie de visita WEB
   */
  private crearCookieVisita() {
    const now = new Date();
    now.setHours(now.getHours() + 1);

    Cookie.set("visita_web_activa", "true", now);
  }

  private buscarDatosUsuario() {

    this.httpClientService.consultarIpInfo().subscribe(
      (resultado: IpApi) => {
        console.log("Response IP Cliente: " + resultado);
        if (resultado != null) {
          this.insertarIteraccionWeb(
            resultado.ip, resultado.country, resultado.region, resultado.city,
            resultado.postal, resultado.loc);
        } else {
          this.insertarIteraccionWebErrorIpApi();
        }
      },
      () => (this.insertarIteraccionWebErrorIpApi()),
      () => { });

  }

  /**
     * Insertar iteraccion en WEB sin parametros
     */
  private insertarIteraccionWebErrorIpApi(): void {
    console.error("Error al llamar a IpApi");
    this.insertarIteraccionWeb('', '', '', '', '', '');
  }

  /**
   * Insertar iteraccion en WEB
   */
  private insertarIteraccionWeb(
    ip: string, pais: string, region: string, ciudad: string,
    codigoPostal: string, coordenadas: string): void {

    this.httpClientService.insertarIteracionWeb(
      ip, window.location.hostname + this.router.url, pais, region, ciudad, codigoPostal,
      coordenadas, this.deviceService.getDeviceInfo().os,
      this.deviceService.getDeviceInfo().device,
      this.deviceService.getDeviceInfo().browser).subscribe(
        (resultado: Resultado) => {
          console.log("Response iteraciion: " + resultado);
        },
        () => (console.error("Error al insertar iteraccion")),
        () => {
        });
  }

}
