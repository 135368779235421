import { Component , OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'web-casa-mella';

  constructor(
    private url: LocationStrategy,
    private route: Router) { }

  ngOnInit(): void {
    this.comprobarUrl();
  }

  comprobarUrl(): void {

    console.log('Path: '+this.url.path());
    console.log('Host: '+window.location.hostname);

    if (!this.isLocalhost() && this.isBaseUrl() && this.comprobarUrlBaseCasaLola()) {
      this.route.navigate(['./casa-lola']);
    }
  }

  comprobarUrlBaseCasaManola(): boolean {
    let baseUrlCasaManola: boolean = false;
    if (window.location.hostname === environment.urlCasaManola) {
      baseUrlCasaManola = true;
    }
    return baseUrlCasaManola;
  }

  comprobarUrlBaseCasaLola(): boolean {
    let baseUrlCasaManola: boolean = false;
    if (window.location.hostname === environment.urlCasaLola) {
      baseUrlCasaManola = true;
    }
    return baseUrlCasaManola;
  }

  isBaseUrl(): boolean {
    let comprobarBaseUrl: boolean = false;
    if (this.url.path().toString === this.url.getBaseHref().toString) {
      comprobarBaseUrl = true;
    }
    return comprobarBaseUrl;
  }

  isLocalhost(): boolean {
    return Boolean(window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
      );
  }

}
