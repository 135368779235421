import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClientService } from './../../core/http-client/http-client.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Comprobar si se tiene acceso a Taberna Mella en este entorno
   */
  public comprobarAccesoTabernaMella(): boolean {
    return environment.isTabernaMellaVisible;
  }

  /**
   * Comprobar si se tiene acceso a Casa Lola en este entorno
   */
  public comprobarAccesoCasaLola(): boolean {
    return environment.isCasaLolaVisible;
  }

}
