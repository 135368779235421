<section id="map-casa-lola" class="mapa section-bg">
    <div class="container">

        <div class="section-title">
            <h2>{{ 'mapa.titulo' | translate }}</h2>
            <p>{{ 'mapa.descripcion' | translate }}</p>
        </div>

        <div class="map-container">
            <div class="map-frame">
              <div id="map"></div>
            </div>
        </div>

    </div>
</section>