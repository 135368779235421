<nav class="navbar navbar-expand-md navbar-light bg-light">
    <a href="/casa-manola" class="navbar-brand">Casa Manola</a>
    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
        <div class="navbar-nav">
            <a *ngIf="comprobarAccesoCasaLola()" href="/casa-lola" class="nav-item nav-link">Casa Lola</a>
            <a *ngIf="comprobarAccesoTabernaMella()" href="/taberna-mella" class="nav-item nav-link">Taberna
                Mella</a>
            <app-util-idioma-menu></app-util-idioma-menu>
        </div>
    </div>
</nav>