import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { RegistrarAccesoService } from 'src/app/core/registrar-acceso/registrar-acceso.service'

@Component({
  selector: 'app-casa-manola',
  templateUrl: './casa-manola.component.html',
  styleUrls: ['./casa-manola.component.css']
})
export class CasaManolaComponent implements OnInit {

  public emailContactoCasaManola = '';
  public telefonoContactoCasaManola = '';
  public telefonoFijoContactoCasaManola = '';

  constructor(
    private titleService: Title,
    private registrarAccesoService: RegistrarAccesoService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Casa Manola');
    this.emailContactoCasaManola = environment.emailContactoCasaManola;
    this.telefonoContactoCasaManola = environment.telefonoContactoCasaManola;
    this.telefonoFijoContactoCasaManola = environment.telefonoFijoContactoCasaManola;
    this.registrarAccesoService.insertarIteraccionEnWeb();
  }


}
