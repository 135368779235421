import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

import { MapUtilService } from 'src/app/core/map-util/map-util.service';

@Component({
  selector: 'app-map-casa-manola',
  templateUrl: './map-casa-manola.component.html',
  styleUrls: ['./map-casa-manola.component.css']
})
export class MapCasaManolaComponent implements OnInit {

  constructor(private mapUtilService: MapUtilService) { }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {

    let map: L.Map;
    
    map = this.mapUtilService.createMapMarker(
      42.78131, -8.56217, 
      'map', 'Casa Mella', 
      'https://maps.apple.com/place?q=Casa%20Manola&ll=42.781402%2C-8.56226&auid=5662925869621190165&lsp=9902&address=Calle%20Raris%2C%2080%2C%2015883%20Teo%2C%20A%20Coru%C3%B1a%2C%20Espa%C3%B1a', 
      'https://goo.gl/maps/mFjiRYeXS2tQ4z9w7', 'https://goo.gl/maps/mFjiRYeXS2tQ4z9w7');
  
  }

}
