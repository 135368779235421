import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RegistrarAccesoService } from 'src/app/core/registrar-acceso/registrar-acceso.service'

@Component({
  selector: 'app-taberna-mella',
  templateUrl: './taberna-mella.component.html',
  styleUrls: ['./taberna-mella.component.css']
})
export class TabernaMellaComponent implements OnInit {

  constructor(
    private titleService: Title,
    private registrarAccesoService: RegistrarAccesoService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Casa Manola');
    this.registrarAccesoService.insertarIteraccionEnWeb();
  }

}
