import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-menu-casa-lola',
  templateUrl: './menu-casa-lola.component.html',
  styleUrls: ['./menu-casa-lola.component.css']
})
export class MenuCasaLolaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Comprobar si se tiene acceso a Taberna Mella en este entorno
   */
   public comprobarAccesoTabernaMella(): boolean {
    return environment.isTabernaMellaVisible;
  }

  /**
   * Comprobar si se tiene acceso a Casa Lola en este entorno
   */
   public comprobarAccesoCasaLola(): boolean {
    return environment.isCasaLolaVisible;
  }

  /**
   * Comprobar si se tiene acceso a Casa Manola en este entorno
   */
   public comprobarAccesoCasaManola(): boolean {
    return environment.isCasaManolaVisible;
  }

}
