import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CasaManolaComponent } from './casa-manola/casa-manola.component';
import { MenuComponent } from './casa-manola/menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { TabernaMellaComponent } from './taberna-mella/taberna-mella.component';
import { UtilIdiomaMenuComponent } from './util-idioma-menu/util-idioma-menu.component';
import { MenuTabernaComponent } from './taberna-mella/menu-taberna/menu-taberna.component';
import { Error404Component } from './generic/error404/error404.component';
import { CasaLolaComponent } from './casa-lola/casa-lola.component';
import { MenuCasaLolaComponent } from './casa-lola/menu-casa-lola/menu-casa-lola.component';
import { MapCasaManolaComponent } from './casa-manola/map-casa-manola/map-casa-manola.component';
import { MapCasaLolaComponent } from './casa-lola/map-casa-lola/map-casa-lola.component';
import { MapTabernaMellaComponent } from './taberna-mella/map-taberna-mella/map-taberna-mella.component';

@NgModule({
  declarations: [
    AppComponent,
    CasaManolaComponent,
    MenuComponent,
    FooterComponent,
    TabernaMellaComponent,
    UtilIdiomaMenuComponent,
    MenuTabernaComponent,
    Error404Component,
    CasaLolaComponent,
    MenuCasaLolaComponent,
    MapCasaManolaComponent,
    MapCasaLolaComponent,
    MapTabernaMellaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
